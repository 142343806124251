import React from 'react';
import { Switch, Route } from 'react-router-dom';
import KeysManagement from './KeysManagement';
import Onboarding from './Onboarding';
import OnboardingKeys from './OnboardingKeys';
import SelectKeyType from './SelectKeyType';
import Welcome from './Welcome';
import BankAccount, { BankAccountSuccess } from './BankAccount';
import KeyDetail from './KeyDetail';
import KeyValidation from './KeyValidation';
import NewAddressKey from './NewAddressKey';
import NewAddressKeyWithType from './NewAddressKeyWithType';
import Portability from './Portability';
import Faq from './Faq';
import Ownership from './Ownership';
import QRCodePayment from './QRCodePayment';
import SharedDataInfo from './SharedDataInfo';
import { NewCheckout, CheckoutSuccess, KeySelection } from './Checkout';
import NoCreatedKeys from './NoCreatedKeys';

const Routes = () => (
  <Switch>
    <Route path="/" exact component={KeysManagement} />
    <Route path="/faq" exact component={Faq} />
    <Route path="/faq/:active" exact component={Faq} />
    <Route path="/welcome" exact component={Welcome} />
    <Route path="/keys-management" exact component={KeysManagement} />
    <Route path="/keys-management/detail" exact component={KeyDetail} />
    <Route path="/bank-account/:origin" exact component={BankAccount} />
    <Route path="/bank-account-success" exact component={BankAccountSuccess} />
    <Route path="/onboarding" exact component={Onboarding} />
    <Route path="/onboarding-keys" exact component={OnboardingKeys} />
    <Route path="/new-key/validation" exact component={KeyValidation} />
    <Route path="/new-key/value" exact component={NewAddressKey} />
    <Route path="/new-key/type" exact component={SelectKeyType} />
    <Route path="/new-key/:keyType" component={NewAddressKeyWithType} />
    <Route path="/portability/:action" component={Portability} />
    <Route path="/ownership/:action" component={Ownership} />
    <Route path="/qrcode-payment/" component={QRCodePayment} />
    <Route path="/checkout" component={NewCheckout} />
    {/* <Route path="/checkout" component={KeySelection} /> */}
    <Route path="/checkout/success/" component={CheckoutSuccess} />
    <Route path="/shared-data-info/" component={SharedDataInfo} />
    <Route path="/no-keys" component={NoCreatedKeys} />
  </Switch>
);

export default Routes;
