import React from 'react';
import { Row, Button } from '@sumup/circuit-ui';
import { useFeature } from 'flagged';
import { ReactComponent as IllustrationNoPixKey } from 'assets/icons/icon-NoKeysCreatedAlert.svg';

import {
  FieldWrapperPixKey,
  TitlePixKey,
  SubTitlePixKey,
  ImageNewPix,
  Container,
} from './NoKeysCreated.styles';
import { NoKeys } from '../Checkout';

const NoKeysCreated = () => {
  const onClickHandler = () => {
    window.location.href = '/checkout';
  };
  const isNewFlowEnabled = useFeature('isCreatedKeysEnable');

  return isNewFlowEnabled ? (
    <FieldWrapperPixKey>
      <Container>
        <Row>
          <ImageNewPix>
            <IllustrationNoPixKey />
          </ImageNewPix>
          <TitlePixKey>Uma chave Pix será criada para você</TitlePixKey>
          <SubTitlePixKey>
            Como você não tem nenhuma chave cadastrada, vamos criar uma chave
            aleatória para você poder vender com Pix. Compartilhando essa chave
            com alguém, você também compartilhará seu Nome e seu CPF.
          </SubTitlePixKey>
          <Button
            stretch={true}
            variant="primary"
            onClick={() => onClickHandler()}
          >
            Continuar
          </Button>
        </Row>
      </Container>
    </FieldWrapperPixKey>
  ) : (
    <>
      <NoKeys />
      <Button
        stretch={true}
        variant="primary"
        onClick={() => onClickHandler('/new-key/type', 'manage')}
      >
        Cadastrar nova chave
      </Button>
    </>
  );
};
export default NoKeysCreated;
