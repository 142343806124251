import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from '@sumup/circuit-ui';

import { appSetStep } from 'actions';
import { QRCodePaymentContainer, QRCodeScreen } from 'containers/Checkout';
import { appSteps } from 'modules/appSteps';
import { formatValue } from 'modules/content';
import { executeNativeBack, isExecutingInBankApp } from 'modules/dom';

const QRCodePayment = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { qrcode, price, description } = useSelector((state) => state.checkout);

  useEffect(() => {
    dispatch(
      appSetStep({
        currentStep: appSteps.qrcodePayment.step,
        beforeGoBack: () => executeNativeBack(),
      })
    );
  }, [dispatch]);

  const handleNewPix = () => {
    if (isExecutingInBankApp) {
      executeNativeBack();
    } else {
      history.replace('/checkout');
    }
  };

  return (
    <>
      <QRCodePaymentContainer>
        <QRCodeScreen
          qrcodeValue={qrcode || ''}
          paymentPrice={price}
          qrcodeDescription={
            price
              ? 'Escaneie e pague pelo celular'
              : 'Escaneie, digite o valor e pague pelo celular'
          }
          paymentDescription={description}
          waitforPayment={false}
        />
        <div className="button-container">
          <Button onClick={handleNewPix} stretch={true} variant="secondary">
            Receber novo Pix
          </Button>
        </div>
      </QRCodePaymentContainer>
    </>
  );
};

export default QRCodePayment;
