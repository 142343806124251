import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@sumup/circuit-ui';
import QRCode from 'components/QRCode';
import { priceFormatter } from 'modules/helpers';
import { executeCopy } from 'modules/dom';
import { showAlert } from 'actions/app';
import { useDispatch, useSelector } from 'react-redux';
import { STATUS } from 'constants';
import { formatValue } from 'modules/content';
import { ADDRESS_KEY_TYPE_CPF } from 'constants/';
import {
  QRCodeCard,
  QRCodeContainer,
  DescriptionItem,
  RemarkContainer,
  InfoIcon,
  StyledSpinner,
  StyledCopyPasteIcon,
  InfoContainer,
  ItemTitle,
  ItemValue,
  DescriptionHeader,
} from './Checkout.styles';

const QRCodeScreen = ({
  qrcodeValue,
  qrcodeDescription,
  paymentPrice,
  paymentDescription,
  waitforPayment,
}) => {
  const {
    profile: { fullName, nationalId },
  } = useSelector((state) => state.account);
  const dispatch = useDispatch();
  const onCopyPasteClickHandler = () => {
    executeCopy(atob(qrcodeValue));
    dispatch(
      showAlert('O código do QR Code foi copiado com sucesso', {
        status: STATUS.SUCCESS,
      })
    );
  };

  return (
    <>
      <QRCodeCard data-testid="qr-code-screen">
        {paymentPrice > 0 ? (
          <div className="qr-amount-block">
            <Text className="qr-amount">Valor</Text>
            <Text className="qr-price" bold>
              {priceFormatter(paymentPrice)}
            </Text>
          </div>
        ) : (
          <></>
        )}
        <QRCodeContainer>
          <QRCode value={atob(qrcodeValue)} />
        </QRCodeContainer>
        {waitforPayment && <StyledSpinner />}
        <Text>{qrcodeDescription}</Text>
        {qrcodeValue && (
          <RemarkContainer>
            <DescriptionItem>
              <ItemTitle>Pix Copia e Cola</ItemTitle>
              <ItemValue id="qrcode-value">{atob(qrcodeValue)}</ItemValue>
            </DescriptionItem>
            <StyledCopyPasteIcon onClick={onCopyPasteClickHandler} />
          </RemarkContainer>
        )}
      </QRCodeCard>
      <InfoContainer>
        <InfoIcon />
        <Text className="qr-info">
          Os seus dados serão visualizados por quem acessar este QR Code.
        </Text>
      </InfoContainer>
      <DescriptionHeader>
        <Text>DESTINO</Text>
      </DescriptionHeader>
      <DescriptionItem>
        <ItemTitle>Titular da conta</ItemTitle>
        <ItemValue>{fullName}</ItemValue>
      </DescriptionItem>
      <DescriptionItem>
        <ItemTitle>CPF</ItemTitle>
        <ItemValue>
          {formatValue(nationalId, ADDRESS_KEY_TYPE_CPF.canonical)}
        </ItemValue>
      </DescriptionItem>
      <DescriptionItem>
        <ItemTitle>Instituição</ItemTitle>
        <ItemValue>SumUp Bank</ItemValue>
      </DescriptionItem>
      {paymentDescription != null ? (
        <DescriptionItem>
          <ItemTitle>Descrição</ItemTitle>
          <ItemValue>{paymentDescription}</ItemValue>
        </DescriptionItem>
      ) : (
        <></>
      )}
    </>
  );
};

QRCodeScreen.propTypes = {
  paymentDescription: PropTypes.string,
  paymentPrice: PropTypes.number,
  qrcodeDescription: PropTypes.string.isRequired,
  qrcodeValue: PropTypes.string.isRequired,
  waitforPayment: PropTypes.bool.isRequired,
};

export default QRCodeScreen;
