import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Text, Button } from '@sumup/circuit-ui';
import { useDispatch } from 'react-redux';
import MaskedMoney from 'components/MaskedMoney';
import { BottomBar } from 'containers/Layout';

import { checkoutGenerateQR } from 'actions';
import { useBottomBar } from 'hooks';

import { FieldWrapper, DescriptionInput } from './Checkout.styles';

const PRICE_CURRENT_STEP = 'price';
const DESCRIPTION_CURRENT_STEP = 'description';

const CheckoutScreen = () => {
  const [bottombarRef] = useBottomBar();
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(PRICE_CURRENT_STEP);
  const [price, setPrice] = useState();

  const onPriceChange = (value) => setPrice(value);

  const handleValue = (value) => {
    if (!value) {
      return value;
    }
    return Number(price);
  };

  const onClickHandler = () => {
    const input = {
      amount: handleValue(price),
      bankAccountCreation: false,
      additionalData: document.getElementById('description')?.value || null,
      initiatorTxId: '',
      key: '',
    };

    if (currentStep === DESCRIPTION_CURRENT_STEP) {
      dispatch(checkoutGenerateQR(input));
    } else {
      setCurrentStep(DESCRIPTION_CURRENT_STEP);
    }
  };

  const onClickSetBlankHandler = () => {
    setPrice(null);
    onClickHandler();
  };

  return (
    <>
      {currentStep === 'price' ? (
        <FieldWrapper data-testid="checkout-screen">
          <Text className="title">
            Qual <strong>valor</strong> você quer cobrar? Definir o valor é
            opcional
          </Text>
          <MaskedMoney onChangeInput={onPriceChange} currentValue={price} />
        </FieldWrapper>
      ) : (
        <FieldWrapper data-testid="checkout-screen" id="descriptionInput">
          <Text className="title">
            Deseja adicionar alguma <strong>descrição</strong>? Você pode deixar
            em branco.
          </Text>
          <Text className="sub-title">
            Digite até 40 caracteres. A descrição ficará visível para quem fizer
            o pagamento.
          </Text>
          <DescriptionInput
            id="description"
            defaultValue=""
            hideLabel={true}
            type="text"
            maxLength="40"
            placeholder="Digite aqui..."
          />
        </FieldWrapper>
      )}
      <BottomBar ref={bottombarRef}>
        {currentStep === PRICE_CURRENT_STEP ? (
          <Button
            onClick={onClickSetBlankHandler}
            stretch={true}
            variant="secondary"
          >
            Não definir valor
          </Button>
        ) : (
          <></>
        )}
        <Button
          disabled={currentStep === PRICE_CURRENT_STEP ? !price : false}
          onClick={onClickHandler}
          stretch={true}
          variant="primary"
        >
          Continuar
        </Button>
      </BottomBar>
    </>
  );
};

export default CheckoutScreen;
